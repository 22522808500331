import React, { useState, useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { marked } from 'marked';

import { Heading4 } from '@latitude/heading';
import Faq from '@/components/Faq/Faq';
import { Box } from '@/components/Box/Box';
import HowToApply from '@latitude/how-to-apply';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import ImportantInformation from '@/components/ImportantInformation/ImportantInformation';
import Layout from '@/components/layout';
import LoanRates from '@/components/LoanRates/LoanRates';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '@/data/pages/home-renovation-loans.json';
import PLData from '@/data/pages/personal-loan.json';
import { EstimateRateWidgetSection } from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import FeatureTiles from '@/components/FeatureTiles/FeatureTiles';
import CalcData from '../data/pages/loans/loan-repayment-calculator.json';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { CardGroup, CardGroupItem } from '@/components/CardGroup/CardGroup';
import { FeaturesSlider } from '@latitude/features-slider';
import HowToApplyList from '@/components/HowToApply/HowToApplyList';
import ArticleTile from '../templates/life-done-better/ArticleTile';
import {
  ALIGN,
  BREAKPOINT,
  COLOR,
  MARGIN,
  SOFT_QUOTE_LOANS_AU_URL
} from '../utils/constants';
import { PLEligibilityCriteria } from '../components/PersonalLoanPageContent/PersonalLoanPageContent';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

import footerData from '@/data/json/footer.json';
import heroImage from '../images/hero/home-renovation.webp';
import SvgInline from '@latitude/svg-inline';
import Button from '../components/Button/Button';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';

const HomeButton = styled(Button)`
  && {
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    && {
      font-size: 15px;
      width: 100%;
      white-space: break-spaces;
    }
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;

const HomeRenoPage = ({ data, location }) => {
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  useEffect(() => {
    sessionStorage.setItem('purpose', 'home-improvements'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  return (
    <Layout
      location={location}
      canonical={location.href}
      metaDesc={PageData.metaDesc}
      title={PageData.title}
      customFooter={footerData}
    >
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />

          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              title={
                <>
                  Home Renovation
                  <br />
                  Loans
                </>
              }
              subTitle={
                <>
                  We’ve got the tools to turn your house into a dream home.
                  Apply for a Latitude Renovation Loan online in just 7 minutes
                  <span className="conditional-text">
                    {' '}
                    and get a response to your application in 60 seconds.
                  </span>
                </>
              }
              pageImage={heroImage}
              pageImageAlt="home renovation loan"
            >
              <div className="row text-white">
                <div className="col-6 col-md-5 text-left text-left">
                  <HomeButton
                    href={applyUrl}
                    trackId="personal-loans-button"
                    trackEventData={{
                      label: 'Get Rate Estimate',
                      location: 'Hero',
                      category: 'button-link'
                    }}
                    trackProductId={['PLAULF-WEB']}
                    className="button--primary"
                  >
                    Get Rate Estimate
                  </HomeButton>
                </div>
                <div className="col-1 p-0 text-center">
                  <SvgInline name="stopwatch" />
                </div>
                <div className="col-5 pt-1 col-md-6 text-left HeroContent__insettext">
                  Check your interest rate in 2 minutes with no impact to your
                  credit score.
                </div>
              </div>
            </PageHeaderSection>
          )}

          <div
            className="d-none d-lg-block"
            css="position:relative; z-index:10;"
          >
            <StickyNavigationBranded
              items={PageData.nav}
              phoneNumber={PLData.content.phoneNumber}
              ctaHref={applyUrl}
              ctaText="Get Rate Estimate"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: 'sticky'
              }}
              trackId="sticky-get-started"
              offsetElem="[data-sticky-navigation-offset]"
            />
          </div>
          <EstimateRateWidgetSection
            purpose="home-improvements"
            parentOnChangeEvent={() => {
              setSoftQuoteHref(redirectUrl());
            }}
          />
          <LoanRates
            isBranded
            rateBoxType="variableSym"
            rateBoxType2="fixedSym"
            fees={PLData.content.aboutTheLoan}
            determineRateLink
          />
          <FeesAndCharges data={PLData.content.feesAndCharges} />
          <FeatureTiles
            id="calculators-and-tools"
            heading={CalcData.content.calculatorsTitle}
            tiles={CalcData.content.calculators}
          />
          <FeaturesSlider
            key={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            id="why-us"
            data={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .featureCards
            }
            className="why-choose pt-4 pt-md-5"
            heading={
              (state?.featureSliderData?.[0] || featureSliderData[0]).heading
            }
            subheading={
              (state?.featureSliderData?.[0] || featureSliderData[0])
                .description
            }
            css={`
              h2 {
                @media (min-width: ${BREAKPOINT.SM}) {
                  font-size: 32px !important;
                  line-height: 36px !important;
                }
              }
              && {
                background-color: #f8f8f8;
                padding-top: 40px !important;
                padding-bottom: 40px;

                .card-icon__image svg {
                  fill: inherit !important;
                  stroke: inherit !important;
                }

                @media (min-width: ${BREAKPOINT.LG}) {
                  padding-top: 72px !important;
                  padding-bottom: 49px;
                }
              }
            `}
          />
          <HowToApply
            id="how-to-apply"
            box1={<HowToApplyList variant="sym" />}
            box2={<PLEligibilityCriteria />}
          />

          <Box.Section
            backgroundColor={COLOR.GREY5}
            paddingTop={MARGIN.M32}
            paddingBottom={MARGIN.M32}
          >
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Recommended reads
            </Heading4>
            <CardGroup>
              {data.allMarkdownRemark.edges.map(item => (
                <CardGroupItem key={item.node.frontmatter.path}>
                  <ArticleTile isBranded {...item.node.frontmatter} />
                </CardGroupItem>
              ))}
            </CardGroup>
          </Box.Section>

          <Faq data={PageData.faq} />
          <ImportantInformation
            data={require('@/data/json/disclaimer/personal-loan1.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
          />
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'LoanOrCredit',
                name: PLData.content.loanDetails.personal.name,
                loanTerm: PLData.content.loanDetails.personal.loanTerm,
                annualPercentageRate:
                  PLData.content.loanDetails.personal.annualPercentageRate,
                amount: PLData.content.loanDetails.personal.amount
              })
            }}
          />
        </Box>
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/life-done-better/articles/*.md"
        }
        frontmatter: {
          title: {
            in: [
              "How Do Colours Affect Your Home?"
              "5 Things You Should Look for in a Builder"
              "How Much Money Should I Spend on a Renovation?"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            thumbnail
          }
        }
      }
    }
  }
`;

export default HomeRenoPage;
